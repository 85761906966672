.about-project {
    min-height: 608px;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 70px;
}

.about-project__section-name {
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;

    align-self: flex-start;
    margin: 0;

    border-bottom: 1px solid #DADADA;
    width: calc(100vw - 140px);
    margin-bottom: 70px;
    padding-bottom: 23px;
}

.about-project__grid-description {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    column-gap: 40px;
    row-gap: 26px;
}

.about-project__text {
    margin: 0;

    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.8px;
}

.about-project__text:last-of-type {
    margin-top: 0;
}

.about-project__description {
    margin: 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.about-project__grid-timeline {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-template-rows: auto auto;
    grid-auto-flow: column;
    text-align: center;
    margin-top: 110px;
    width: calc(100vw - 140px);
}

.about-project__duration {
    background: #303030;

    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    margin: 0;
    padding: 10px 0;
}

.about-project__duration:first-of-type {
    color: #000;
    background: #3DDC84;
}

.about-project__course-name {
    color: #8B8B8B;

    font-size: 14px;
    font-weight: 500;
    line-height: 17px;

    margin: 0;
    padding: 9px 0;
}

@media screen and (max-width: 835px) {
    .about-project {
        min-height: 585px;
        padding: 0 50px;
    }

    .about-project__section-name {
        width: calc(100vw - 100px);
    }

    .about-project__grid-description {
        column-gap: 30px;
        row-gap: 22px;
    }

    .about-project__description {
        font-size: 12px;
        line-height: 18px;
    }

    .about-project__grid-timeline {
        width: calc(100vw - 100px);
        margin-top: 93px;
    }
}

@media screen and (max-width: 420px) {
    .about-project {
        min-height: 641px;
        padding: 0 18px;
        justify-content: flex-start;
    }

    .about-project__section-name {
        font-size: 18px;
        letter-spacing: -0.72px;
        margin-top: 70px;
        width: calc(100vw - 36px);
        padding-bottom: 28px;
        margin-bottom: 60px;
    }

    .about-project__grid-description {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
        row-gap: 16px;
    }

    .about-project__text {
        font-size: 18px;
        letter-spacing: -0.72px;
    }

    .about-project__text_with-margin {
        margin-top: 41px;
    }

    .about-project__description {
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 41px;
    }

    .about-project__grid-timeline {
        width: calc(100vw - 36px);
        grid-template-columns: 100px 1fr;
        margin-top: 19px;
    }

    .about-project__duration {
        font-size: 11px;
    }

    .about-project__course-name {
        font-size: 11px;
    }
}