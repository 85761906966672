.techs {
    min-height: 625px;
    background: #272727;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: 0 70px;
}

.techs__section-name {
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;

    align-self: flex-start;
    margin: 0;

    border-bottom: 1px solid #DADADA;
    width: calc(100vw - 140px);
    padding-bottom: 23px;
    margin-bottom: 90px;
}

.techs__title {
    margin: 0;

    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -2px;
}

.techs__subtitle {
    margin: 0;

    font-size: 14px;
    font-weight: 400;
    line-height: 20px; 
    letter-spacing: -0.56px;
    text-align: center;

    margin-top: 26px;
    width: 460px;
}

.techs__grid-container {
    list-style: none;
    padding: 0;

    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 10px;

    margin-top: 100px;
    margin-bottom: 0;
}

.techs__grid-element {
    margin: 0;

    border-radius: 10px;
    background: #303030;
    padding: 22px 0;
    width: 90px;
    text-align: center;

    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.56px;
}

@media screen and (max-width: 835px) {
    .techs {
        min-height: 567px;
        padding: 0 50px;
    }

    .techs__section-name {
        width: calc(100vw - 100px);
        margin-bottom: 80px;
    }

    .techs__subtitle {
        margin-top: 22px;
    }

    .techs__grid-container {    
        margin-top: 83px;
        padding: 0 10px;
    }

    .techs__grid-element {
        padding: 21px 0;
        width: 84px;

        font-size: 12px;
        letter-spacing: -0.48px;
    }
}

@media screen and (max-width: 745px) {

    .techs__grid-container {    
        width: calc(100vw - 100px);
        padding: 0;
    }

    .techs__grid-element {
        width: auto;
    }
}

@media screen and (max-width: 650px) {
    .techs {
        min-height: 651px;
    }

    .techs__subtitle {
        max-width: calc(100vw - 100px);
    }

    .techs__grid-container {
        grid-auto-flow: row;
        grid-template-columns: repeat(2, 1fr);
        row-gap: 10px;
    }
}

@media screen and (max-width: 420px) {
    .techs {
        padding: 0 18px;
        background: #202020;
    }

    .techs__section-name {
        font-size: 18px;
        letter-spacing: -0.72px;
    }

    .techs__section-name {
        width: calc(100vw - 36px);
        padding-bottom: 28px;
        margin-bottom: 60px;
    }

    .techs__title {
        font-size: 30px;
        letter-spacing: -1.2px;
        line-height: normal;
    }

    .techs__subtitle {
        max-width: calc(100vw - 28px);

        font-size: 11px;
        line-height: 16px; 
        letter-spacing: -0.44px;

        margin-top: 24px;
    }

    .techs__grid-container {
        margin-top: 50px;
        width: calc(100vw - 142px);
    }
}