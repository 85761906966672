@import url(./vendor/normalize.css);
@import url(./vendor/fonts/fonts.css);

@import url(./components/Preloader/Preloader.css);

@import url(./components/Header/Header.css);
@import url(./components/NavBar/NavBar.css);

@import url(./components/Login/Login.css);
@import url(./components/Register/Register.css);

@import url(./components/Main/Main.css);
@import url(./components/Promo/Promo.css);
@import url(./components/AboutProject/AboutProject.css);
@import url(./components/Techs/Techs.css);
@import url(./components/Portfolio/Portfolio.css);
@import url(./components/Footer/Footer.css);

@import url(./components/Movies/Movies.css);
@import url(./components/SearchForm/SearchForm.css);
@import url(./components/MoviesCard/MoviesCard.css);
@import url(./components/MoviesCardList/MoviesCardList.css);
@import url(./components/SavedMovies/SavedMovies.css);

@import url(./components/Profile/Profile.css);

@import url(./components/ErrorNotFound/ErrorNotFound.css);

.page {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Inter', Arial, sans-serif;
  text-rendering: optimizeLegibility;
  background-color: #202020;
  color: #FFFFFF;
  font-style: normal;

  min-height: 100vh;
  margin: 0 auto;
}
