.portfolio {
    box-sizing: border-box;
    min-height: 1072px;
    padding: 110px 70px 0;
}

.portfolio__section-name {
    font-size: 22px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.88px;

    margin: 0;

    border-bottom: 1px solid #DADADA;
    width: calc(100vw - 140px);
    padding-bottom: 23px;
    margin-bottom: 66px;
}

.portfolio__container {
    display: flex;
    justify-content: space-between;
}

.portfolio__title {
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -2px;

    margin: 0;
}

.portfolio__subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;

    margin: 0;
    margin: 18px 0 26px;
}

.portfolio__text {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; 

    margin: 0;
    margin-bottom: 99px;
    max-width: 600px;
}

.portfolio__link-github {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    text-decoration: none;
}

.portfolio__link-github:hover {
    opacity: 0.7;
    cursor: pointer;
}

.portfolio__photo {
    width: 270px;
    height: 327px;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-size: cover;
}

.portfolio__links {
    list-style: none;
    margin: 0;
    padding: 0;
}

.portfolio__links-title {
    color: #8B8B8B;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;

    margin: 0;
    margin: 101px 0 50px;
}

.portfolio__link {
    text-decoration: none;
    display: flex;
    justify-content: space-between;
    margin-top: 19px;

    border-bottom: 1px solid #DADADA;
    width: calc(100vw - 140px);
    padding-bottom: 10px;
}

.portfolio__link_none {
    border-bottom: none;
}

.portfolio__link:hover {
    opacity: 0.7;
    cursor: pointer;
}

.portfolio__link-text {
    color: #FFF;
    font-size: 30px;
    font-weight: 400;
    line-height: 50px; 
    letter-spacing: -1.2px;

    margin: 0;
}

.portfolio__arrow {
    width: 22px;
    height: 59px;
}

@media screen and (max-width: 1050px) {
    .portfolio__text {
        max-width: 410px;
    }

    .portfolio__photo {
        width: 255px;
        height: 307px;
    }
}

@media screen and (max-width: 835px) {
    .portfolio {
        min-height: 994px;
        padding: 90px 50px 0;
    }

    .portfolio__section-name {
        width: calc(100vw - 100px);
    }

    .portfolio__title {
        font-size: 40px;
        line-height: 40px;
        letter-spacing: -1.6px;
    }

    .portfolio__subtitle {
        font-size: 12px;
        line-height: 18px;
        margin: 16px 0;
    }

    .portfolio__text {
        max-width: 364px;
        font-size: 12px;
        line-height: 18px;

        margin-bottom: 87px;
    }

    .portfolio__links-title {
        margin-top: 91px;
    }

    .portfolio__link {
        margin-top: 23px;
    }

    .portfolio__link-text {
        font-size: 28px;
        letter-spacing: -1.12px;
    }

    .portfolio__arrow {
        width: 18px;
        height: 57px;
    }
}

@media screen and (max-width: 730px) {
    .portfolio__text {
        max-width: 280px;
    }
}

@media screen and (max-width: 550px) {
    .portfolio {
        min-height: 1194px;
        padding: 70px 18px 0;
    }

    .portfolio__section-name {
        font-size: 18px;
        letter-spacing: -0.72px;
        width: calc(100vw - 36px);
        padding-bottom: 28px;
        margin-bottom: 56px;
    }

    .portfolio__container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .portfolio__photo {
        width: 292px;
        height: 352px;

        margin-bottom: 40px;
    }

    .portfolio__title {
        font-size: 30px;
        line-height: normal;
        letter-spacing: -1.2px;
    }

    .portfolio__subtitle {
        font-size: 11px;
        line-height: 16px;

        margin: 20px 0;
    }

    .portfolio__text {
        font-size: 11px;
        line-height: 16px;

        margin-bottom: 40px;
    }

    .portfolio__links-title {
        margin: 70px 0 40px;
        font-size: 14px;
    }

    .portfolio__link {
        width: calc(100vw - 36px);
        margin-top: 21px;
        padding-bottom: 17px;
    }

    .portfolio__link-text {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: -0.72px;
    }

    .portfolio__arrow {
        width: 12px;
        height: 24px;
    }
}