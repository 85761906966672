.promo {
    min-height: 513px;
    padding: 20px 40px 0;

    box-sizing: border-box;
}

.promo__container {
    border-radius: 10px;
    background: #272727;

    min-height: 493px;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.promo__title{
    text-align: center;
    font-size: 50px;
    font-weight: 400;
    line-height: 58px;
    letter-spacing: -2px;

    margin: 0;
    margin: 160px 0 151px;
    max-width: 730px;
}

.promo__links-container {
    display: flex;
    gap: 10px;
}

.promo__link{
    width: 96px;
    height: 36px;
    text-align: center;
    text-decoration: none;

    border: none;
    border-radius: 10px;
    color: inherit;
    background-color: #303030;
  
    box-sizing: border-box;

    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    padding-top: 10px;
}

.promo__link:hover {
    opacity: 0.7;
    cursor: pointer;
}

@media screen and (max-width: 835px) {
    .promo {
        padding: 20px 20px 0;
    }
    
    .promo__container{
        padding-bottom: 30px;
    }

    .promo__title{
        font-size: 40px;
        line-height: 52px;
        letter-spacing: -1.6px;
    
        margin: 0;
        margin: 350px 0 314px;
        max-width: 680px;
    }
}

@media screen and (max-width: 420px) {
    .promo {
        padding: 14px 14px 0;
    }

    .promo__container{
        padding-bottom: 17px;
    }

    .promo__title{
        font-size: 29px;
        line-height: 37px;
        letter-spacing: -1.16px;
    
        margin: 0;
        margin: 220px 0 212px;
    }

    .promo__links-container {
        gap: 6px;
    }

    .promo__link {
        font-size: 10px;
        width: 82px;
        height: 26px;
        padding-top: 5px;
    }

}