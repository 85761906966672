.header {
    max-width: 1280px;
    min-height: 74px;
    padding: 0 70px 0;
    margin: 0 auto;

    box-sizing: border-box;
    
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .header__logo {
    height: 38px;
    width: 38px;
  }

  .header__logo:hover {
    opacity: 0.8;
    cursor: pointer;
  }
  
@media screen and (max-width: 835px) {
    .header{
        padding: 0 30px 0;
    }
}

@media screen and (max-width: 420px) {
    .header{
        padding: 0 14px 0;
    }
}

