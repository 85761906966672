.search {
    min-height: 228px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    padding-top: 80px;
    box-sizing: border-box;
}

.search__form {
    width: calc(100vw - 140px);
    border-bottom: 1px solid #424242;
}

.search__input-container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr min-content;
    column-gap: 10px;
}

.search__input {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 8px;
    background: #2F2F2F;
    padding: 16px 20px;
    box-sizing: border-box;
    color: white;
}

.search__icon {
    width: 44px;
    height: 44px;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 5px;
    background-image: url('../../images/search-icon.svg');
    background-size: cover;
    background-repeat: no-repeat;
}

.search__checkbox-container {
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 29px 0 40px;
}

.search__checkbox {
    display: inline-block;    
	height: 16px;    
	line-height: 16px;  
	margin-right: 10px;      
	position: relative;
	vertical-align: middle;
	font-size: 14px;
	user-select: none;	
}

.search__checkbox .search__checkbox-switch {
	position: relative;	
	display: inline-block;
	box-sizing: border-box;			
	width: 36px;	
	height: 16px;
	border: 1px solid rgba(0, 0, 0, .1);
	border-radius: 25%/50%;	
	vertical-align: top;
	background: #eee;
	transition: .2s;
}

.search__checkbox .search__checkbox-switch:before {
	content: '';
	position: absolute;
	top: 1px;
	left: 1px;	
	display: inline-block;
	width: 12px;	
	height: 12px;
	border-radius: 50%;
	background: white;
	box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
	transition: .15s;
}

.search__checkbox input[type=checkbox] {
	display: block;	
	width: 0;
	height: 0;	
	position: absolute;
	z-index: -1;
	opacity: 0;
}
.search__checkbox input[type=checkbox]:not(:disabled):active + .search__checkbox-switch:before {
	box-shadow: inset 0 0 2px rgba(0, 0, 0, .3);
}
.search__checkbox input[type=checkbox]:checked + .search__checkbox-switch {
	background: #3DDC84;
}
.search__checkbox input[type=checkbox]:checked + .search__checkbox-switch:before {
	transform:translateX(20px);
}

.search__label {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
}

.search__checkbox-switch:hover {
    opacity: 0.8;
    cursor: pointer;
}

.search__icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.search__error {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 139px;
    left: 0px;
}

@media screen and (max-width: 835px) {
    .search__form {
        width: calc(100vw - 60px);
    }

    .search__checkbox-container {
        margin-top: 40px;
    }
}

@media screen and (max-width: 420px){
    .search__form {
        width: calc(100vw - 28px);
    }

    .search__input {
        font-size: 14px;
        line-height: 18px;

        padding: 13px;
    }

    .search__label {
        font-size: 11px;
    }

    .search__checkbox-container {
        margin-top: 47px;
    }

    .search__checkbox {
        margin-right: 13px;
    }
}
