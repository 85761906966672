.profile {
    display: flex;
    flex-direction: column;
    align-items: center;

    min-height: 626px;
    margin-top: 74px;
}

.profile__title {
    text-align: center;
    font-size: 24px;
    font-weight: 500;
    line-height: normal;

    margin: 0;
}

.profile__info {
    width: 410px;
    display: flex;
    flex-direction: column;

    margin-top: 123px;
}

.profile__fields {
    display: inline-flex;
    justify-content: space-between;
}

.profile__fields:first-of-type {
    border-bottom: 1px solid #424242;
    padding-bottom: 16px;
}

.profile__fields:last-of-type {
    padding-top: 18px;
}

.profile__text {
    font-size: 11px;
    font-weight: 500;
    line-height: normal;

    margin: 0;
}

.profile__input {
    background: transparent;
    color: white;
    border: 0;
    padding: 0;
    
    text-align: right;
    font-size: 11px;
    font-weight: 400;
    line-height: normal;

    margin: 0;
}

.profile__input:focus {
    outline: none;
    box-shadow: 0px 0px 3px #3DDC84;
}

.profile__input-error {
    color: #EE3465;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin-top: 5px;
}

.profile__links {
    margin-top: 223px;
    display: flex;
    flex-direction: column;
}

.profile__edit {
    background: transparent;
    border: 0;
    padding: 0;
    margin-bottom: 16px;

    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
}

.profile__edit:hover {
    opacity: 0.8;
    cursor: pointer;
}

.profile__edit_inactive {
    color: rgb(126, 124, 124);
}

.profile__edit.profile__edit_inactive:hover {
    cursor: not-allowed;
    opacity: 1;
}

.profile__exit {
    color: #EE3465;
    text-align: center;
    font-size: 13px;
    font-weight: 500;
    line-height: normal;

    text-decoration: none;
}

.profile__exit:hover {
    opacity: 0.7;
    cursor: pointer;
}

.profile__edit:hover {
    opacity: 0.7;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .profile {
        margin-top: 235px;
    }

    .profile__info {
        margin-top: 98px;
    }

    .profile__links {
        margin-top: 212px;
    }
}

@media screen and (max-width: 450px) {
    .profile {
        margin-top: 70px;
    }

    .profile__info {
        width: 260px;
        margin-top: 80px;
    }

    .profile__fields:last-of-type {
        padding-top: 18px;
    }

    .profile__links {
        margin-top: 380px;
    }

    .profile__edit {
        font-size: 12px;
    }

    .profile__exit {
        font-size: 12px;
    }
}