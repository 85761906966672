.error-not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-not-found__title {
    margin: 0;
    margin: 246px 0 5px;

    text-align: center;
    font-size: 140px;
    font-weight: 400;
    line-height: normal;
}

.error-not-found__subtitle {
    margin: 0;

    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}

.error-not-found__link {
    padding: 0;
    border: 0;
    background: transparent;

    color: #4285F4;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    margin-top: 184px;
}

.error-not-found__link:hover {
    opacity: 0.7;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .error-not-found {
        min-height: 1024px;
    }

    .error-not-found__title {
        margin-top: 407px;
    }
    
}

@media screen and (max-width: 420px) {
    .error-not-found {
        min-height: 780px;
    }

    .error-not-found__title {
        font-size: 80px;
        margin: 330px 0 10px;
    }

    .error-not-found__subtitle {
        font-size: 12px;
    }
    
    .error-not-found__link {
        font-size: 12px;
        margin-top: 284px;
    }
}