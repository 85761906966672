.main-saved-movies {
    max-width: 1280px;
    min-height: 100vh;
    margin: 0 auto;
    padding: 0 70px;
}

@media screen and (max-width: 835px) {
    .main-saved-movies {
        padding: 0 30px 0;
    }
}