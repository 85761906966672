.cards-list {
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.cards-list__not-found-error {
    color: #fff;
    font-size: 32px;
    position: absolute;
    top: 50%;
}

.cards-list__container {
    list-style: none;
    padding: 0;
    margin: 0;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    column-gap: 30px;
    row-gap: 60px;
}

.cards-list__more-films-button {
    width: 320px;
    height: 36px;
    flex-shrink: 0;
    background: transparent;
    border: 1px solid #424242;
    border-radius: 5px;
    margin: 76px 0 53px;

    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}

.cards-list__more-films-button:hover {
    opacity: 0.8;
    cursor: pointer;
}

@media screen and (max-width: 1150px) {
    .cards-list__container {
        height: 1070px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(4, 1fr);
        grid-auto-rows: 0;
        overflow-y: hidden;
    }
}

@media screen and (max-width: 835px) {
    .cards-list {
        margin-top: 80px;
    }

    .cards-list__container {
        row-gap: 47px;
    }

    .cards-list__more-films-button {
        margin: 58px 0 63px;
    }
}

@media screen and (max-width: 720px) {
    .cards-list__container {
        height: 1348px;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-auto-rows: 0;
        overflow-y: hidden;
    }

    .cards-list__more-films-button {
        width: 240px;
    }
}

@media screen and (max-width: 420px) {
    .cards-list__not-found-error {
        color: #fff;
        font-size: 22px;
        position: absolute;
        top: 40%;
    }

    .cards-list {
        margin-top: 50px;
    }

    .cards-list__container {
        height: 1152px;
        row-gap: 37px;
    }

    .cards-list__more-films-button {
        margin-top: 62px;
    }
}