.card {
    position: relative;
}

.card:hover {
    cursor: pointer;
}

.card__image {
    width: 360px;
    height: 201px;
}

.card__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px;
    margin-top: 10px;
}

.card__name {
    font-size: 13px;
    font-weight: 500;
    line-height: normal;

    margin: 0;
}

.card__duration {
    color: #8B8B8B;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    line-height: 10px;

    border-radius: 3px;
    background: #2F2F2F;
    padding: 3px 9px;
    margin: 0;
}

.card__button-saved {
    background: url(../../images/button-saved.svg);
    background-repeat: no-repeat;
    background-size: cover;

    width: 21px;
    height: 21px;
    border: 0;
    padding: 0;

    position: absolute;
    top: 14px;
    right: 14px;

    z-index: 2;
}

.card__button-saved:hover {
    opacity: 0.8;
    cursor: pointer;
}

.card__button-to-save {
    background: url(../../images/button-to-save.svg);
    background-repeat: no-repeat;
    background-size: cover;

    width: 72px;
    height: 21px;
    border: 0;
    padding: 0;

    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 1;
}

.card__button-to-save:hover {
    opacity: 0.8;
    cursor: pointer;
}

.card__button-delete {
    background: url(../../images/button-delete.svg);
    background-repeat: no-repeat;
    background-size: cover;

    width: 21px;
    height: 21px;
    border: 0;
    padding: 0;

    position: absolute;
    top: 14px;
    right: 14px;
    z-index: 2;
}

.card__button-delete:hover {
    display: block;
    cursor: pointer;
    opacity: 0.8;
}

.card__image:hover ~ .card__button_delete {
    display: block;
}

@media screen and (max-width: 810px) {
    .card__image {
        width: 339px;
        height: 190px;
    }

    .card__info {
        padding: 0;
        margin-top: 22px;
    }
}

@media screen and (max-width: 420px){
    .card__image {
        width: 300px;
        height: 168px;
    }

    .card__info {
        margin-top: 13px;
    }

    .card__name {
        font-size: 12px;
    }

    .card__button-saved {
        top: 10px;
        right: 10px;
    }

    .card__button-to-save {
        top: 10px;
        right: 10px;
    }

    .card__button-delete {
        display: block;
    
        position: absolute;
        top: 10px;
        right: 10px;
    }
}